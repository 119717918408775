import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  Alert, // Import InputLabel component
  Backdrop 
} from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Background1 from './background1.avif';
import logo from './fairsure_logo.png';
import qrcode from './qrcode.jpg'
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PhoneIcon from '@mui/icons-material/Phone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import HomeIcon from '@mui/icons-material/Home';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';
import './style.css';
import axios from 'axios';
import JSEncrypt from 'jsencrypt';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import ClipLoader from 'react-spinners/ClipLoader';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight, faCalendarDays, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons';
import { faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import { faShield } from '@fortawesome/free-solid-svg-icons';
import { handleGetRequest, handleRequest } from '../requests';
import { CircleLoader } from 'react-spinners';
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import AlertDialogue from './Alert';
import CountdownTimer from './countdown';



function BalanceCard({ balance, userEmail, merchantName }) {
  return (
    <div>
      <div
        style={{
          marginBottom: '30px',
          display: 'flex',
          flexDirection:"column",
          alignItems: 'center',
          justifyContent: "space-between",
          boxShadow: 'none',
        }}
      >
        <img src={logo} width={30} height={30} />
        <div style={{ display: "flex", flexDirection: "column", margin: "none", padding: "none" }}>
          <h4>{merchantName}</h4>
        </div>

      </div>
      <h3>NGN {balance}</h3>
    </div>
  );
}

const CardWrapper = styled(Card)(({ theme }) => ({
  borderRadius: '30px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  height: '470px', // Fixed height
  width: '100%',
  overflowY: 'auto', // Allow vertical scrolling if content exceeds fixed height
  '&::-webkit-scrollbar': {
    width: '10px', // Width of the scrollbar
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 102, 204, 0.6)', // Color of the thumb
    borderRadius: '10px', // Rounded corners of the thumb
    height: '20px', // Adjust the height of the thumb
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(0, 102, 204, 0.8)', // Color of the thumb on hover
  },
  [theme.breakpoints.up('xs')]: {
    width: '80%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
  [theme.breakpoints.up('md')]: {
    width: '40%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '35%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '35%',
  },
}));


function PaymentCheckout() {
  const [paymentOption, setPaymentOption] = useState('bankCard');
  const [cardScreen, setCardScreen] = useState('screenOne');
  const [ussdScreen, setUssdScreen] = useState('screenOne');
  const [cardNumber, setCardNumber] = useState('');
  const [cardPin, setCardPin] = useState('');
  const [cardOtp, setCardOtp] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [cardInitResponse, setCardInitResponse] = useState([]);
  const [ussdInitResponse, setUssdInitResponse] = useState([]);
  const [expiryDate, setExpiryDate] = useState('');
  const [expiryDate2, setExpiryDate2] = useState('');
  const [cvv, setCvv] = useState('');
  const [showIframe, setShowIframe] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [ussdNumber, setUssdNumber] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [bankTransferInfo, setBankTransferInfo] = useState('');
  
  const [totalBalance, setTotalBalance] = useState('0');
  const [callBack, setCallBack] = useState("");
  const [transRef, setTransRef] = useState('');
  const [merchantName, setMerchantName] = useState('Bill Merchant');
  const [ussdString, setUssdString] = useState('');
  const [codeRef, setCodeRef] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [bankData, setBankData] = useState({});
  const [selectedBankCode, setSelectedBankCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);




  // const [searchParams, setSearchParams] = useSearchParams();
  const searchParams = new URLSearchParams(document.location.search)

  //console.log(searchParams.get("PaymentID"));



  
  // const [searchParams, setSearchParams] = useSearchParams();

  console.log(searchParams.get("PaymentID"));
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [failed, setFailed] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [ussdStatusMessage, setUssdStatusMessage] = useState('')

  const[loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(true);

  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [inputValue3, setInputValue3] = useState('');
  const [inputValue4, setInputValue4] = useState('');
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];


  const [httpBrowserColorDepth, setHttpBrowserColorDepth] = useState("");
  const [httpBrowserLanguage, setHttpBrowserLanguage] = useState("");
  const [httpBrowserScreenHeight, setHttpBrowserScreenHeight] = useState("");
  const [httpBrowserScreenWidth, setHttpBrowserScreenWidth] = useState("");
  const [httpBrowserTimeDifference, setHttpBrowserTimeDifference] = useState("");
  const [userAgentBrowserValue, setUserAgentBrowserValue] = useState("");



  const ENCRYPTION_PUBLIC_KEY = "-----BEGIN RSA PUBLIC KEY-----"
  +"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtz9ok7VaqpjHhG5Pm6Mm"
  +"+7o6VjXawY92vppSpy1fzEeIpZB76Mg+SSgNTiJ9+A5Clvx+VNGLaktU8yR+pxbF"
  +"X/K0h27eHfeYBfTyft5leGo5X6uoZ6VvrVAiyvhBT3lRxe7tumCqZIW9MgdtWlMp"
  +"sEzsuzPUbmWIa+kwUqD4pWxFmRGN2d39FAdo+vsqiZnGgHzv7fFEf9SQjgWhMk2B"
  +"Qs7+P9MuwEFBX/h8+/WCWnbqC3FS8Gdzo6ZUwxI8ZIU7vmpNs/m8wFwmTX6XbTq6"
  +"cIZsUwOwqN93weeQiLKP2QLaxgUC0ham9+MmFLZOxwTjC+sv0qMwd8ZSRI/4Nj39"
  +"4wIDAQAB"
  +"-----END RSA PUBLIC KEY-----";

  const merchantKey = 'LIVE_7AXSCCY8R3HABAN14QP7';
  // const merchantKey = 'LIVE_EJXVHJWNXSPYNW4XBFR2';
  // const merchantKey = 'TEST_RLZYLPWUDWSAUGGYJEJE';



  const authorize3DAction = (paymentId, reference, eciFlag) => {

    // console.log(cardInitResponse);

    const req = {
      "customerEmail": "",
      "customerName": "",
      "customerPhone": "",
      "paymentId": paymentId,
      "transactionId": reference,
      "eciFlag": eciFlag,
    }

    console.log(req);

    handleRequest('/api/pub/submit-otp', req, merchantKey).then((res) => {//this endpoint is a challenge... See why
      if (res.status === 200) {
        setIsLoading(false);
        console.log(res.data);
        setCardInitResponse(res.data);

        // console.log('rrrr: ', res.data)

        // var message = JSON.stringify(res.data);
        // window.parent.postMessage(message, "*");
        
        // setTimeout(() => {
          merchantRedirect(res.data);
        // }, 20000);

      }

    }).catch((err) => { 
      merchantRedirect(err.data);
      console.log(err) });
  }


  const merchantRedirect = (resData) => {
    try {
      
			var payStatus = resData.status === 200 ? resData.data.paymentReference === "09" ? "PENDING" : "SUCCESS" : "FAILED";
			var paidAmount = resData.data.amount;
      var transactionRef = resData.data.transactionRef;

      

        // ************************ Display payment status screen ***********************
        setCardScreen("screenFinal");
        setSuccess(true);
        setSuccessMsg(payStatus === "SUCCESS" ? "Transaction Successful" : "Transaction Confirmation Pending")
        
        //********************Return Control *********************************/


      // var payStatus = resData.responseCode === "00" || resData.responseCode === "10" || resData.responseCode === "11" ? "SUCCESS" : "FAILED";
      // var amo = resData.amount;
      // var transId = resData.transactionRef;
      // var transactionRef = resData.merchantTransactionRef;
      
      console.log(resData.data.callBackUrl);
      setTimeout(() => {
      resData.data.callBackUrl.includes("?") ?
        // window.location = `${resData.data.callBackUrl}&status=${payStatus}&ref=${transactionRef}&transId=${transId}&amount=${paidAmount}` :
        // window.location = `${resData.callback_url}?status=${payStatus}&ref=${transactionRef}&transId=${transId}&amount=${paidAmount}`;

					window.location = resData.data.callBackUrl + "&status=" + payStatus + "&amount=" + paidAmount + "&ref=" + `${transactionRef}` :
					window.location = resData.data.callBackUrl + "?status=" + payStatus + "&amount=" + paidAmount + "&ref=" + `${transactionRef}`;
        }, 20000);
    } catch (error) {
      
      console.log(error);
      console.log(resData.data.callBackUrl);
      resData.data.callBackUrl.includes("?") ?
        window.location = `${resData.data.callBackUrl}&status=FAILED` :
        window.location = `${resData.data.callBackUrl}?status=FAILED`;
      
    }

  }

  const handlePaymentSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(e);
    console.log('Payment submitted');
    switch (paymentOption) {
      case "bankCard":
        const cardDetails = JSON.stringify({
          pan: cardNumber.replace(/\s/g, ''),
          pin: cardPin,
          cvv: cvv,
          // expiryDate: `${state.cardYear.slice(2)}${state.cardMonth}`
          expiryDate: expiryDate2
        });
        console.log(cardDetails);


        // initialize encryption 
        const encrypt = new JSEncrypt(); // initialization
        encrypt.setPublicKey(ENCRYPTION_PUBLIC_KEY); // set encryption key
        const encryptedCardData = encrypt.encrypt(cardDetails);
        console.log(encryptedCardData);

        const req = {
          "amount": totalBalance,
          // "callBackUrl": window.location.href,
          "callBackUrl": callBack,
          // "callBackUrl": "http://localhost:3000",
          "currency": "NGN",
          "customerId": userEmail,
          "data": encryptedCardData,
          "deviceInformation": {
            "deviceChannel": "WEB",
            "httpBrowserColorDepth": httpBrowserColorDepth,
            "httpBrowserJavaEnabled": true,
            "httpBrowserJavaScriptEnabled": true,
            "httpBrowserLanguage": httpBrowserLanguage,
            "httpBrowserScreenHeight": httpBrowserScreenHeight,
            "httpBrowserScreenWidth": httpBrowserScreenWidth,
            "httpBrowserTimeDifference": httpBrowserTimeDifference / -60,
            "userAgentBrowserValue": userAgentBrowserValue
          },
          // "transactionRef": `${Math.floor((Math.random() * 1000000000) + 1)}`
          "transactionRef": transRef
        };
        
        handleRequest('/api/pub/card-payment', req, merchantKey).then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            setCardInitResponse(res.data.data);
            if (res.data.data.responseCode === "T0") {
              setCardScreen("screenThree");
            } else {
              if (res.data.data.responseCode === "S0") {
                // console.log(`https://checkout.fairsurepayment.com.ng:8091/api/fairsure/3Ds-auth?ACSUrl=${res.data.data.ACSUrl}&JWT=${res.data.data.jwt}&MD=${res.data.data.MD}&embedded=true`);
                // setIframeSrc(`/api/fairsure/3Ds-auth?ACSUrl=${res.data.data.ACSUrl}&JWT=${res.data.data.jwt}&MD=${res.data.data.MD}`);
                // setShowIframe(true);
                // window.parent.location.href = `https://checkout.fairsurepayment.com.ng:8091/api/fairsure/3Ds-auth?ACSUrl=${res.data.data.ACSUrl}&JWT=${res.data.data.jwt}&MD=${res.data.data.MD}&embedded=true`;
                // // parent.window.location.href = `https://checkout.fairsurepayment.com.ng:8091/api/fairsure/3Ds-auth?ACSUrl=${res.data.data.ACSUrl}&JWT=${res.data.data.jwt}&MD=${res.data.data.MD}&embedded=true`;
                // // window.location.replace(`https://checkout.fairsurepayment.com.ng:8091/api/fairsure/3Ds-auth?ACSUrl=${res.data.data.ACSUrl}&JWT=${res.data.data.jwt}&MD=${res.data.data.MD}&embedded=true`);
                // // window.location.replace(`/api/fairsure/3Ds-auth?ACSUrl=${res.data.data.ACSUrl}&JWT=${res.data.data.jwt}&MD=${res.data.data.MD}`);
              
                const dsData = {
                  "isDS": true,
                  "DS": `https://checkout.fairsurepayment.com.ng:8091/api/fairsure/3Ds-auth?ACSUrl=${res.data.data.ACSUrl}&JWT=${res.data.data.jwt}&MD=${res.data.data.MD}&embedded=true`
                };
                      
                var message = JSON.stringify(dsData);
                window.parent.postMessage(message, "*");
              } else {
                // ******************* Display error message ***********************
              setError(true); // Set error to true when an error occurs
              setErrorMessage('An error occured please try again later!')
              }
              setError(true); // Set error to true when an error occurs
              setErrorMessage('An error occured please try again later!')

            } 
          } }).catch((err) => {
            console.log(err);
            setError(true); // Set error to true when an error occurs
            setErrorMessage('An error occured please try again later!')
        
            // Use setTimeout to reset the error state to false after 5 seconds
            setTimeout(() => {
              setError(false);
              setErrorMessage('')
            }, 5000); // 5000 milliseconds (5 seconds)
          })
          .finally(() => {
            setLoading(false);
          });

        break;

      default:
        break;
    }
  };


  const resendOtpAction = () => { }


  const submitOtpAction = () => {
    // Set loading to true before making the request
    setLoading(true);

    const req = {
      "customerEmail": userEmail,
      "customerName": " ",
      "customerPhone": " ",
      "otp": cardOtp,
      "paymentId": cardInitResponse.paymentId,
      "transactionId": cardInitResponse.transactionRef
    };
    handleRequest('/api/pub/submit-otp', req, merchantKey)
      .then((res) => {
        if (res.status === 200) {
          setCardScreen("screenFinal");
          setSuccess(true);
          setCardInitResponse(res.data);
          
          var payStatus = res.status === 200 ? res.data.paymentReference === "09" ? "PENDING" : "SUCCESS" : "FAILED";
          setSuccessMsg(payStatus === "SUCCESS" ? "Transaction Successful" : "Transaction Confirmation Pending")

          //********************Return Control *********************************/

          console.log('rrrr: ', res.data)

          var message = JSON.stringify(res.data);
          window.parent.postMessage(message, "*");
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true); // Set error to true when an error occurs
        setErrorMessage('An error occured please try again later!')
    
        // Use setTimeout to reset the error state to false after 5 seconds
        setTimeout(() => {
          setError(false);
          setErrorMessage('')
        }, 5000); // 5000 milliseconds (5 seconds)
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    // if(showIframe){
    //   window.parent.location.href = iframeSrc;
    // }
    
    //?PaymentID=1198277242&Ref=pCItpuDWRgKSPjUP8MX0&EciFlag=07
    // PaymentID=474551610&Ref=bMHWekPVgY5TrLFPCm40&EciFlag=07
    if ((searchParams.get("PaymentID") !== null && searchParams.get("PaymentID") !== "" && searchParams.get("PaymentID") !== undefined)
      && (searchParams.get("Ref") !== null && searchParams.get("Ref") !== "" && searchParams.get("Ref") !== undefined)
      && (searchParams.get("EciFlag") !== null && searchParams.get("EciFlag") !== "" && searchParams.get("EciFlag") !== undefined)) {
      authorize3DAction(searchParams.get("PaymentID"), searchParams.get("Ref"), searchParams.get("EciFlag"));
    } else{

      handleGetRequest('/api/pub/list-ussd-banks', merchantKey)
        .then((res) => {
          if (res.status === 200) {
            console.log('Ussd Data fetched');
  
            // Initialize an empty object to store bank data
            const bankData = {};
  
            // Loop through the response data and populate the bankData object
            res.data.data.forEach((bank) => {
              // Use the bank name as the key and store the entire bank object as the value
              bankData[bank.BankName] = bank;
            });
  
            // Update the state variable with the bankData object
            setBankData(bankData);
            console.log(bankData)
          }
        })
        .catch((err) => {
          console.log(err);
        });
  
  
  
      if (isLoading && document.readyState === 'complete') {
        try {
          window.addEventListener('message', function (event) {
          
            if (isJsonObject(event.data)) {
              console.log("Checking event data...", event.data)
              let imo = JSON.parse(event.data);
              if (imo !== null && imo.amount !== null && imo.amount !== undefined) {
                // console.log("Message received from the parent: " + event.data); // Message received from parent
                // configuration.paymentParameters = JSON.parse(event.data);
                let messagePost = JSON.parse(event.data);
                // configuration.paymentParameters = event.data;
                console.log(messagePost);
                setIsLoading(false);
  
  
                setCallBack(messagePost.callback_url);
                setTotalBalance(messagePost.amount);
                setMerchantName(messagePost.merchantName);
                // setTotalBalance(messagePost.amount);
                setTransRef(messagePost.transactionId);
                setUserEmail(messagePost.email);
                // document.getElementById('amount-paying').innerHTML = "Pay &#8358;";
                // document.getElementById('amount').innerHTML = configuration.paymentParameters.amount / 100;
                // isw.hostedFields.create(configuration, callback);
              }
  
            }
          });
  
        } catch (error) {
          console.log(error)
        }
      }
    }

  }, []);





  const handleUssdPayment = () => {
    // Set loading to true before making the request
    setLoading(true);
    const req = {
      "amount": totalBalance,
      "bankCode": selectedBankCode,
      // "reference": `${Math.floor((Math.random() * 1000000000) + 1)}`
      "reference": transRef
    };

    handleRequest('/api/pub/generate-ussd', req, merchantKey)
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          const responseData = res.data.data;
          setUssdInitResponse(responseData);
          
  
          // Check if ussdString is not empty
          if (responseData.ussdString !== '') {
            setUssdString(responseData.ussdString);
            setCodeRef(responseData.codeRef);
            // Use a setTimeout to update the screen after setting the state
            setTimeout(() => {
              setUssdScreen('screenTwo');
            }, 2);
          }

          console.log(responseData);
        } else { 
          setError(true); // Set error to true when an error occurs
          setErrorMessage('An error occured please try again later!')
    
          // Use setTimeout to reset the error state to false after 5 seconds
          setTimeout(() => {
            setError(false);
            setErrorMessage('')
          }, 5000); // 5000 milliseconds (5 seconds)
        }  
    
      })
      .catch((err) => {
        console.log(err);
      
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleVerifyUssdPayment = () => {
    setLoading(true);

    const req = {
      'amount': parseInt(totalBalance),
      'codeReference': codeRef
    };

//     const res = {"data": {
//       "status": 200,
//       "data": {
//           "message": "Success",
//           "amountPaid": 100,
//           "paid": true
//       }
//   }
// };

    handleRequest('/api/pub/ussd-status', req, merchantKey)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.message === 'Payment Pending') {
            setUssdStatusMessage('Your Transaction is Currently Pending, click the button again after some minutes.');
          } else if (res.data.data.message === 'Success') {
            //setUssdStatusMessage('Your transaction was successful.');
            setUssdScreen('screenThree');

            //********************Return Control *********************************/
  
            res.data.amount = totalBalance;
            res.data.transactionRef = transRef;
            res.data.itransactionId = codeRef;
            console.log('rrrr: ', res.data)
  
            var message = JSON.stringify(res.data);
            setTimeout(() => {
              window.parent.postMessage(message, "*");
            }, 20000);
          } else if (res.data.data.message === 'Payment Failed') {
            //setUssdStatusMessage('Your payment has failed, kindly try again.');
            setUssdScreen('screenFour');
          } else {
            setUssdStatusMessage('');
          }
          console.log('Ussd Successful');
          console.log(res.data);
          // Set a timeout to clear the status message after 10 seconds
          setTimeout(() => {
            setUssdStatusMessage(''); // Clear the status message
          }, 10000); // 10000 milliseconds (10 seconds)
        } else { 
          setError(true); // Set error to true when an error occurs
          setErrorMessage('An error occured please try again later!')
    
          // Use setTimeout to reset the error state to false after 5 seconds
          setTimeout(() => {
            setError(false);
            setErrorMessage('')
          }, 5000); // 5000 milliseconds (5 seconds)
        }
      })
      .catch((err) => {
        console.log(err);
        
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const isJsonObject = (data) => {
    try {
      JSON.parse(data)
    } catch (error) {
      return false;
    }
    return true;
  };

  const doNextAction = () => {
    if (paymentOption === 'bankCard') {
      switch (cardScreen) {
        case 'screenOne':
          setCardScreen('screenTwo');
          break;
        case 'screenTwo':
          setCardScreen('screenThree');
          break;
        default:
          break;
      }
    }
  };





  const copyUssdCodeToClipboard = () => {
    if (ussdString) {
      navigator.clipboard.writeText(ussdString)
        .then(() => {
          setCopied(true);
          // Reset the 'copied' state to 'false' after 5 seconds
          setTimeout(() => {
            setCopied(false);
          }, 5000); // 5000 milliseconds (5 seconds)
        })
        .catch((error) => {
          console.error('Unable to copy to clipboard:', error);
        });
    }
  };






  const paymentOptions = [
    {
      key: 'bankCard',
      text: '',
      icon: <FontAwesomeIcon icon={faCreditCard} size="1x" style={{ color: "#0f0f0f" }} />,
    },
    {
      key: 'bankTransfer',
      text: '',
      icon: <FontAwesomeIcon icon={faBuildingColumns} size="1x" style={{ color: "#0f0f0f" }} />,
    },
    {
      key: 'ussd',
      text: '',
      icon: <FontAwesomeIcon icon={faHashtag} size="1x" style={{ color: "#0f0f0f" }} />,
    },
    {
      key: 'qrcode',
      text: '',
      icon: <QrCodeScannerIcon />,
    },
  ];









  // Define the functions to format card number and expiry date
  const formatCardNumber = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedValue = '';

    for (let i = 0; i < numericValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedValue += ' ';
      }
      formattedValue += numericValue[i];
    }

    setCardNumber(formattedValue);
  };

  const formatExpiryDate = (e) => {
    // setExpiryDate2(e.target.value)
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedDisplayValue = '';
  
    for (let i = 0; i < numericValue.length; i++) {
      if (i > 0 && i % 2 === 0) {
        formattedDisplayValue += '/';
      }
      formattedDisplayValue += numericValue[i];
    }
    const inputValueArray = inputValue.split('/');

    setExpiryDate2(inputValueArray[1] + inputValueArray[0]);

    setExpiryDate(formattedDisplayValue);
  };
  







  const handleInputChange = (index, event) => {
    const value = event.target.value;

    // Check if the user pressed the "Backspace" key and the input is empty
    if (event.key === 'Backspace' && value.length === 0 && index > 0) {
      // Move focus to the previous input field
      inputRefs[index - 1].current.focus();
      return; // Don't update the input value
    }

    // Update the input values and cardPin
    if (index === 0) {
      setInputValue1(value);
    } else if (index === 1) {
      setInputValue2(value);
    } else if (index === 2) {
      setInputValue3(value);
    } else if (index === 3) {
      setInputValue4(value);
    }

    // Automatically focus on the next input field
    if (index < inputRefs.length - 1 && value.length === 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  useEffect(() => {
    // Concatenate input values into cardPin
    const newCardPin = inputValue1 + inputValue2 + inputValue3 + inputValue4;
    setCardPin(newCardPin);
    console.log(cardPin);
  }, [inputValue1, inputValue2, inputValue3, inputValue4]);

  // Get all the values from the inputValues array as a single string

  // Now, you can use the cardPin variable as needed




  useEffect(() => {
    // Function to retrieve and set browser-related information
    const getBrowserInfo = () => {
      // Get browser color depth
      const colorDepth = window.screen.colorDepth;
      setHttpBrowserColorDepth(colorDepth);

      // Get browser language
      const language = window.navigator.language;
      setHttpBrowserLanguage(language);

      // Get browser screen height
      const screenHeight = window.screen.height;
      setHttpBrowserScreenHeight(screenHeight);

      // Get browser screen width
      const screenWidth = window.screen.width;
      setHttpBrowserScreenWidth(screenWidth);

      // Get browser time difference from UTC (in minutes)
      const timeDifference = new Date().getTimezoneOffset();
      setHttpBrowserTimeDifference(timeDifference);

      // Get user agent browser value
      const userAgent = window.navigator.userAgent;
      setUserAgentBrowserValue(userAgent);
    };

    // Call the function to get and set the initial browser-related information
    getBrowserInfo();

    // Attach an event listener to update the information if it changes
    window.addEventListener('resize', getBrowserInfo);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', getBrowserInfo);
    };
  }, []);
  
  const handleClose = () => { 
    setOpen(false);
  }
  
  
  return (
    <>
    
    <Backdrop
      sx={{ color: 'rgb(59, 220, 248)', zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={isLoading}
      onClick={() => {}}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        style={{ position: 'absolute', top: 1, right: 10 }}
      >
        <CloseIcon />
      </IconButton>
        <div
          style={{
            display: 'flex',
            flexDirection:"column",
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily:"Andale Mono, monospace",
            minHeight: '100vh',
            width:"100%"
            /*backgroundImage: `url(${Background1})`,*/
            /*backgroundColor:"#F0F8FF",*/
            /*backgroundColor: 'rgba(255, 255, 255, 0.2)', // Background color with transparency
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backdropFilter: 'blur(8px)',*/ // Adjust the blur amount as needed
          }}
        >
          <CardWrapper variant="outlined">
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xxs={2.2} xs={2.2} sm={2.2} md={2} lg={1.5} xxl={1.5}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <List disablePadding>
                      {paymentOptions.map(({ key, text, icon }) => (
                        <ListItem
                          key={key}
                          disableGutters
                          button
                          onClick={() => setPaymentOption(key)}
                          style={{ marginBottom: '2rem' }}
                        >
                          <ListItemIcon
                            style={{
                              color: paymentOption === key ? 'black' : 'inherit',
                            }}
                          >
                            {icon}
                          </ListItemIcon>
                          <ListItemText primary={text} />
                          {paymentOption === key && <div className="blue-dot"></div>}
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Grid>
                <Divider orientation='vertical' flexItem />
                <Grid item xxs={10} xs={9.5} sm={9.5} md={9.5} lg={9.5} xl={9.5} xxl={9.5}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <form onSubmit={handlePaymentSubmit}>
                      <BalanceCard balance={totalBalance} userEmail={userEmail} merchantName={merchantName} />
                      {paymentOption === 'bankCard' && (
                        <>
                          {cardScreen === 'screenOne' && (
                            <>
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <InputLabel htmlFor="cardNumber" style={{ fontSize: "15px", color: "black", marginLeft: "0.5rem" }}>Card Number</InputLabel>
                                <div className="styled-input">
                                  <input
                                    type="text"
                                    id="cardNumber"
                                    inputMode="numeric"
                                    maxLength={23}
                                    placeholder="0000 0000 0000 0000"
                                    style={{ border: "none", outline: "none", width: "100%" }}
                                    required
                                    value={cardNumber}
                                    onChange={formatCardNumber}
                                  />
                                  <FontAwesomeIcon icon={faCcVisa} size="2x" style={{ color: "#0f0f0f" }} />
                                </div>
                              </div>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <InputLabel htmlFor="expiryDate" style={{ fontSize: "15px", color: "black", marginLeft: "0.5rem" }}>Expiry Date</InputLabel>
                                    <div className="styled-input">
                                      <input
                                        type="text"
                                        id="expiryDate"
                                        inputMode="numeric"
                                        maxLength={5}
                                        placeholder="MM/YY"
                                        style={{ border: "none", outline: "none", width: "100%" }}
                                        required
                                        value={expiryDate}
                                        onChange={formatExpiryDate}
                                      />
                                      <FontAwesomeIcon icon={faCalendarDays} size="2x" style={{ color: "#0f0f0f" }} />
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <InputLabel htmlFor="cvv" style={{ fontSize: "15px", color: "black", marginLeft: "0.5rem" }}>CVV</InputLabel>
                                    <div className="styled-input">
                                      <input
                                        type="password"
                                        inputMode="numeric"
                                        maxLength={3}
                                        id="cvv"
                                        placeholder="123"
                                        required
                                        style={{ border: "none", outline: "none", width: "100%" }}
                                        value={cvv}
                                        onChange={(e) => setCvv(e.target.value)}
                                      />
                                      <FontAwesomeIcon icon={faLock} size="2x" style={{ color: "#0f0f0f" }} />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                          )}
                          {cardScreen === 'screenTwo' && (
                            <div style={{ display: 'flex', width: '100%', alignItems:"center", justifyContent:"center"}}>
                              <input
                                type="password"
                                maxLength="1"
                                inputMode="numeric"
                                className="pin-input-field"
                                placeholder="*"
                                value={inputValue1}
                                onChange={(event) => handleInputChange(0, event)}
                                onKeyUp={(event) => handleInputChange(0, event)}
                                ref={inputRefs[0]}
                              />
                              <input
                                type="password"
                                maxLength="1"
                                inputMode="numeric"
                                className="pin-input-field"
                                placeholder="*"
                                value={inputValue2}
                                onChange={(event) => handleInputChange(1, event)}
                                onKeyUp={(event) => handleInputChange(1, event)}
                                ref={inputRefs[1]}
                              />
                              <input
                                type="password"
                                maxLength="1"
                                inputMode="numeric"
                                className="pin-input-field"
                                placeholder="*"
                                value={inputValue3}
                                onChange={(event) => handleInputChange(2, event)}
                                onKeyUp={(event) => handleInputChange(2, event)}
                                ref={inputRefs[2]}
                              />
                              <input
                                type="password"
                                maxLength="1"
                                inputMode="numeric"
                                className="pin-input-field"
                                placeholder="*"
                                value={inputValue4}
                                onChange={(event) => handleInputChange(3, event)}
                                onKeyUp={(event) => handleInputChange(3, event)}
                                ref={inputRefs[3]}
                              />
                            </div>
                          )}
                          {cardScreen === 'screenThree' && (
                            <>
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <InputLabel htmlFor="cardNumber" style={{ fontSize: "15px", color: "black", marginLeft: "0.5rem" }}>OTP</InputLabel>
                                <div className="styled-input">
                                  <input
                                    type="number"
                                    id="cardOtp"
                                    inputMode="numeric"
                                    maxLength={16}
                                    placeholder="****"
                                    style={{ border: "none", outline: "none", width: "100%", textAlign:"center"}}
                                    required
                                    value={cardOtp}
                                    onChange={(e) => setCardOtp(e.target.value)}
                                  />
                                  <FontAwesomeIcon icon={faLock} size="2x" style={{ color: "#0f0f0f" }} />
                                </div>
                              </div>
                            </>
                          )}
                          {cardScreen === 'screenFinal' && (
                            <>
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"center" }}>
                                {success &&  
                                  <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', fontSize: '70px' }} />
                                    <h4>{successMsg}</h4>
                                    {/* You can adjust the color and size as needed */}
                                  </div>
                                }
                                {failed && 
                                  <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                                    <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '70px' }} />
                                    <h4>Transaction Failed</h4>
                                    {/* You can adjust the color and size as needed */}
                                  </div> 
                                }
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {paymentOption === 'ussd' && (
                        <>
            
                          {ussdScreen === 'screenOne' && (
                            <div>
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <InputLabel htmlFor="selectBank" style={{ fontSize: "15px", color: "black", marginLeft: "0.5rem" }}>Select a Bank</InputLabel>
                                <Select
                                  id="selectBank"
                                  required
                                  value={selectedBank}
                                  onChange={(e) => {
                                    setSelectedBank(e.target.value);
                                    // Set the selectedBankCode based on the selected bank
                                    const bankName = e.target.value;
                                    if (bankData.hasOwnProperty(bankName)) {
                                      setSelectedBankCode(bankData[bankName].BankCode);
                                    } else {
                                      setSelectedBankCode(''); // Reset if the selected bank is not found in bankData
                                    }
                                  }}
                                  sx={{
                                    backgroundColor: '#f2f2f2',
                                    color: '#333',
                                    borderRadius: '30px',
                                    width: '100%',
                                    fontSize: "10px",
                                    marginBottom: "1rem"
                                  }}
                                >
                                  <MenuItem value="">
                                    <em>Select a Bank</em>
                                  </MenuItem>
                                  {Object.keys(bankData).map((bankName) => (
                                    <MenuItem key={bankName} value={bankName}>
                                      {bankName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <InputLabel htmlFor="ussdNumber" style={{ fontSize: "15px", color: "black", marginLeft: "0.5rem" }}>Bank Code</InputLabel>
                                <div className="styled-input">
                                  <input
                                    type="text"
                                    id="ussdNumber"
                                    placeholder="*1234#"
                                    required
                                    style={{ border: "none", outline: "none", width: "100%" }}
                                    value={selectedBankCode}
                                    disabled
                                  />
                                  <FontAwesomeIcon icon={faPhoneFlip} size="2x" style={{ color: "#0f0f0f" }} />
                                </div>
                              </div>
                              
                            </div>
                          )}


                          {ussdScreen === 'screenTwo' && (
                            <div>
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <InputLabel htmlFor="ussdNumber" style={{ fontSize: "15px", color: "black", marginLeft: "0.5rem" }}>USSD Code</InputLabel>
                                <div className="styled-input">
                                  <input
                                    type="text"
                                    id="ussdNumber"
                                    placeholder="*1234#"
                                    required
                                    style={{ border: "none", outline: "none", width: "100%" }}
                                    value={ussdString}
                                  />
                                </div>
                              </div>
                              <ul style={{fontSize:"11px", color:"grey"}}> 
                                <li>Note: the generated code expires in 25mins</li>
                              </ul>
                              <CountdownTimer />
                              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                                <button
                                  type="button"
                                  onClick={copyUssdCodeToClipboard}
                                  className="copy-button"
                                  disabled={copied}
                                >
                                  {copied ? 'Copied' : 'Copy'}
                                </button>
                                {loading ? ( 
                                  <CircularProgress color="primary" style={{width:"30px", height:"30px", marginTop:"1rem"}}/> 
                                ) : ( 
                                  <button
                                    type="button"
                                    onClick={handleVerifyUssdPayment}
                                    className="copy-button"
                                    style={{width:"100%", marginTop:"1rem"}}
                                  >
                                    I've made this transfer
                                  </button>
                                )}
                                <p style={{fontSize:"12px", color:"darkorange"}}>{ussdStatusMessage}</p>
                              </div>
                            </div>
                          )}

                          {ussdScreen === 'screenThree' && (
                            <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                              <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', fontSize: '70px' }} />
                              <h4>Transaction Successful</h4>
                              {/* You can adjust the color and size as needed */}
                            </div>
                          )}

                          {ussdScreen === 'screenFour' && (
                            <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                              <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '70px' }} />
                              <h4>Transaction Failed</h4>
                              {/* You can adjust the color and size as needed */}
                            </div>
                          )}

                          
                        </>
                      )}
                      {paymentOption === 'bankTransfer' && (
                        <>
                          <Typography variant="p" gutterBottom style={{ marginBottom: "2rem" }}>
                            Bank Transfer
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Please transfer the amount to the following bank account:
                            <br /><br />
                            Bank Name: Zenith Bank
                            <br />
                            Account Number: 1234567890
                            <br /><br />
                            This information will expire in 30 minutes.
                          </Typography>
                        </>
                      )}
                      {paymentOption === 'qrcode' && (
                        <div style={{ textAlign: 'center' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img
                              src={qrcode}
                              alt="QR Code"
                              style={{ maxWidth: '59%', height: 'auto' }}
                            />
                            <Typography variant="p" style={{ marginTop: '16px' }}>
                              Scan the code to pay
                            </Typography>
                          </div>
                        </div>
                      )}
                      <Divider style={{ margin: '16px 0' }} />


                    {/* BUTONS FOR BANK CARD PAYMENT */}
                      {(paymentOption === 'bankCard' && cardScreen === 'screenOne') && (
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => doNextAction()}
                          style={{
                            backgroundColor: '#1E90FF	',
                            borderRadius: '10px',
                            textTransform: 'none',
                            color:"white"
                          }}
                          fullWidth
                          disabled={cardNumber === '' || expiryDate === '' || cvv === ''}
                        >
                          Enter Pin <FontAwesomeIcon icon={faArrowRight} size="1x" style={{ color: "white", marginLeft:"0.5rem" }} />
                        </Button>
                      )}

                      {paymentOption === 'bankCard' && cardScreen === 'screenTwo' && (
                        loading ? ( // Display the loader when loading is true
                          <CircularProgress color="primary" style={{ width: "30px", height: "30px" }} />
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            style={{ backgroundColor: '#1E90FF', borderRadius: '10px', textTransform: "none" }}
                            fullWidth
                          >
                            Confirm Pin
                          </Button>
                        )
                      )}


                      {(paymentOption === 'bankCard' && cardScreen === 'screenThree') && (
                        <>
                          {loading ? (
                            <CircularProgress color="primary" style={{width:"30px", height:"30px"}}/> // Display the loader when loading is true
                          ) : (
                            <Button
                              type="button"
                              variant="contained"
                              onClick={submitOtpAction}
                              style={{ backgroundColor: '#1E90FF', borderRadius: '10px' }}
                              fullWidth
                            >
                              Submit OTP
                            </Button>
                          )}
                          <Button
                            type="button"
                            onClick={() => resendOtpAction()}
                            fullWidth
                          >
                            Resend OTP
                          </Button>
                        </>
                      )}

                      {(paymentOption === 'ussd' && ussdScreen === 'screenOne') && ( 
                      <>
                        {loading ? ( 
                          <CircularProgress color="primary" style={{width:"30px", height:"30px"}}/> 
                        ) : (
                        <Button
                          type="button"
                          variant="contained"
                          style={{ backgroundColor: '#1E90FF', borderRadius: '10px', textTransform:"none"}}
                          fullWidth
                          onClick={handleUssdPayment}
                        >
                          Pay with ussd
                        </Button>
                        )}
                        <p style={{fontSize:"12px"}}>{ussdStatusMessage}</p>
                      </>  
                      )}

                      {((paymentOption === 'bankTransfer')
                        ) && <Button
                          type="submit"
                          variant="contained"
                          style={{ backgroundColor: '#1E90FF', borderRadius: '10px', textTransform:"none"}}
                          fullWidth
                        >
                          I've sent this money
                        </Button>
                      }

                      {((paymentOption === 'qrcode')
                        ) && <Button
                          type="submit"
                          variant="contained"
                          style={{ backgroundColor: '#1E90FF', borderRadius: '10px', textTransform:"none"}}
                          fullWidth
                        >
                          I've sent this money
                        </Button>
                      }
                      
                      
                    </form>
                    
                  </div>
                  
                </Grid>
              </Grid>
        
              
            </CardContent>
            {error && (
              <AlertDialogue severity="error" onClose={() => setError(false)}>
                {errorMessage}
              </AlertDialogue>
            )}

          </CardWrapper>
          <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <FontAwesomeIcon icon={faUnlockKeyhole} size="1x" style={{ color: "black", marginRight:"0.5rem" }} />
            <p style={{color:"black", fontSize:"12px"}}>Payment Secured by Fairsure Solutions</p>
          </div>
        </div>
    </Backdrop>
    </>
  );
}

export default PaymentCheckout;
