import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const AlertDialogue = ({ open, message, severity }) => {

  return (
    <Snackbar open={open} anchorOrigin={{vertical:"bottom", horizontal:"center"}}>
      <Alert severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertDialogue;
