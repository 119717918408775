import { BrowserRouter, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import PaymentCheckout from './Pages/Checkout';


function App() {
  return (
    <div className="App">
      <PaymentCheckout/>
      
      <BrowserRouter basename="/real-check">
      {/* <BrowserRouter> */}
      <Routes>
        <Route path='/' element={<PaymentCheckout/>}/>
        {/* <Route path='/complete-three-ds' element={<ThreeDSTrans/>}/> */}
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
