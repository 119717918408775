import axios from 'axios';


const config = {
    headers: {
      "X-Authentication": "1234567890",
      "key": "***********",
      "Content-Type": "application/json",
    },
  };


export const handleRequest = async(url, data, key) => {
    try {
        config.headers.publickey = key;
        const res = await axios.post(url, data, config);
        return res;
    } catch (er) {
        return er.response;        
    }
}

export const handleGetRequest = async(url, key) => {    
  try {
      config.headers.publickey = key;
      const res = await axios.get(url, config);
      return res;
  } catch (er) {
      return er.response;        
  }
}