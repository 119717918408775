import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const targetTime = 25 * 60 * 1000; // 25 minutes in milliseconds
  
  const [startTime, setStartTime] = useState(new Date().getTime());



  const calculateTimeRemaining = () => {
    const now = new Date();
    const difference = targetTime - (now.getTime() - startTime);

    if (difference <= 0) {
      // Timer has expired
      return { minutes: 0, seconds: 0 };
    }

    const minutes = Math.floor(difference / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { minutes, seconds };
  };
  
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  return (
    <div style={{ display: 'flex' }}>
        {timeRemaining.minutes === 0 ? (
            <span>USSD Code Has Expired</span>
        ) : (
            <>
            <span style={{ marginRight: '0.5rem' }}>Expires In </span>
            <span>{timeRemaining.minutes}</span>:
            <span>{timeRemaining.seconds}</span>
            </>
        )}
    </div>
  );
};

export default CountdownTimer;
